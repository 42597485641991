import React from 'react'
import EvistampLogo from '../../images/zLogoEvistamp.png'
import BricoBrowserLogo from '../../images/social-media-3.svg'
import Pulse from 'react-reveal/Pulse'

const Products = () => {
    return (
        <section className="products" id="products">
            <div className="text-xl md:text-2xl lg:text-3xl bg-mainColor text-white p-1 text-center">Our products</div>
            <div className="sm:text-xl text-lg text-mainColor mt-4 text-center">Some of our Products developed and under development:</div>
            <hr className="horizontalLine"/>
            <div className="mainProducts ">
                <Pulse>
                    <div className="firstProduct">
                        <h5>BricoBrowser</h5>
                        <div className="grid grid-cols-1 lg:grid-cols-2 md:mt-3 md:p-3">
                            <div className="m-auto" title="BricoBrowser"><img className="productsLogo" alt="bricoLogo" src={BricoBrowserLogo}/></div>
                            <div className="text-mainColor tracking-wide text-center md:text-left sm:p-4 md:p-0">The Specialized Browser with superpowers:
                                Multi Screens, Multi Proxies, Multi Workspaces
                            </div>
                        </div>
                    </div>
                </Pulse>
                <Pulse>
                    <div className="firstProduct">
                        <h5>Evistamp</h5>
                        <div className="grid grid-cols-1 lg:grid-cols-2 md:mt-3 md:p-3">
                            <div className="m-auto" title="Evistamp"><img className="productsLogo" alt="EvistampLogo" src={EvistampLogo}/></div>
                            <div className="text-mainColor text-center md:text-left sm:p-4 md:p-0 tracking-wide">Web based and API timestamping solution in multi blockchains</div>
                        </div>
                    </div>
                </Pulse>
            </div>
        </section>
    )

}
export default Products